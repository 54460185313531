<template>
  <!-- ==== Start Form ==== -->
  <form @submit.prevent="validateCreateForm">
    <div class="container">
      <div class="row justify-content-between">
        <!--applys on -->
        <label for="name_input" class="form-label title">
          اذا اشترى العميل
        </label>

        <!-- Start:: Products -->
        <div class="col-lg-12 py-0">
          <div class="col-12 productRow">
            <div class="row p-2">
              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label">
                  <select
                    class="form-control border-0 outline-none"
                    style="box-shadow: none;"
                    v-model.trim="data.buy_products.buy_apply_on"
                    @input="clearDataBuyProductsRow()"
                  >
                    <option
                      v-for="item in applys_on"
                      :key="item.name"
                      :value="item.name"
                    >
                      {{ $t(`${item.name}`) }}
                    </option>
                  </select>
                  <label for="name_input" class="form-label">
                    منتج / قسم
                  </label>
                </div>
              </div>
              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="number"
                    class="form-control"
                    v-model.trim="data.buy_products.buy_quantity"
                  />
                  <label for="name_input" class="form-label">
                    كمية الشراء
                  </label>
                </div>
              </div>

              <div class="productRow p-4">
                <div
                  v-for="(row, index) in data.buy_products.buy_products_rows"
                  :key="row.id"
                >
                  <div class="row productRow">
                    <div
                      class="col-lg-8 py-0"
                      v-if="
                        data.buy_products.buy_apply_on == 'special_categories'
                      "
                    >
                      <div class="input_wrapper top_label">
                        <label class="form-label">
                          {{ $t("forms.labels.category") }}
                        </label>

                        <multiselect
                          v-model="row.buy_category"
                          :options="categories"
                          label="name"
                          track-by="id"
                          placeholder=" "
                          :searchable="true"
                          :allow-empty="false"
                          :show-labels="false"
                        >
                          <!-- <template slot="option" slot-scope="{ option }">
                            <div class="option__desc">
                              <span class="option__title">
                                <strong>{{ option.name }}&nbsp;</strong>
                              </span>

                              <span class="option__small">
                                ({{ option.tag }})
                              </span>
                            </div>
                          </template> -->
                        </multiselect>
                      </div>
                    </div>
                    <div
                      class="col-lg-8 py-0"
                      v-if="
                        data.buy_products.buy_apply_on == 'special_products'
                      "
                    >
                      <div class="input_wrapper top_label">
                        <label class="form-label">
                          {{ $t("forms.labels.product") }}
                        </label>
                        <!-- {{ row.product }} -->
                        <multiselect
                          v-model="row.product"
                          :options="products"
                          :custom-label="nameWithCode"
                          track-by="id"
                          placeholder=" "
                          :searchable="true"
                          :allow-empty="false"
                          :show-labels="false"
                          @input="getProductDetails(index, 'buy')"
                        ></multiselect>
                      </div>
                    </div>

                    <div class="col-11" v-if="row.buy_product_details.length">
                      <main>
                        <v-data-table
                          class="elevation-1 thumb diff_table"
                          :headers="headers"
                          :items="row.buy_product_details"
                          item-key="id"
                          :show-select="true"
                          v-model="row.selected"
                          hide-default-footer
                        >
                          <!-- ================== You Can use any slots you want ================== -->

                          <template v-slot:no-data>
                            {{ $t("table.noData") }}
                          </template>

                          <template v-slot:[`item.item`]="{ item }">
                            <div class="detail">
                              <div class="productRow mb-0 w-100">
                                <div class="detail_show w-100">
                                  <span class="rate">
                                    {{ item.rate_avg }}
                                    <span><i class="fas fa-star"></i></span>
                                  </span>

                                  <div class="image">
                                    <span class="key">الصورة</span>

                                    <img
                                      v-if="item.media"
                                      :src="item.media[0].image"
                                      alt=""
                                    />
                                    <img
                                      v-else
                                      :src="item.product.image"
                                      alt=""
                                    />
                                  </div>

                                  <div class="color">
                                    <span class="key">اللون</span>
                                    <v-chip
                                      :color="
                                        item.color ? item.color.hex : 'لا يوجد'
                                      "
                                      class="value"
                                    ></v-chip>
                                  </div>

                                  <div class="size">
                                    <span class="key">الكمية</span>
                                    <v-chip
                                      color="#0E3958"
                                      class="value text-white"
                                    >
                                      {{
                                        item.quantity
                                          ? item.quantity
                                          : "لا يوجد"
                                      }}
                                    </v-chip>
                                  </div>

                                  <div class="size">
                                    <div class="size">
                                      <span class="key">الحجم</span>
                                      <v-chip
                                        color="#0E3958"
                                        class="value text-white"
                                      >
                                        {{
                                          item.size ? item.size.name : "لا يوجد"
                                        }}
                                      </v-chip>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </template>
                        </v-data-table>
                      </main>
                    </div>

                    <div class="col-1 py-0 d-flex justify-center align-center">
                      <span
                        v-if="
                          index ==
                          data.buy_products.buy_products_rows.length - 1
                        "
                        class="append mx-1"
                        @click="appendBuyRow"
                      >
                        <i class="fas fa-plus-circle"></i>
                      </span>

                      <span
                        v-if="data.buy_products.buy_products_rows.length > 1"
                        class="append mx-1"
                        @click="deleteBuyRow(index)"
                      >
                        <i class="fas fa-minus-circle"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <label for="name_input" class="form-label title">
          يحصل العميل على
        </label>

        <!-- Start:: Products -->
        <div class="col-lg-12 py-0">
          <div class="col-12 productRow">
            <div class="row p-2">
              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label">
                  <select
                    class="form-control border-0 outline-none"
                    style="box-shadow: none;"
                    v-model.trim="data.get_products.get_apply_on"
                    @input="clearDataProductsRow"
                  >
                    <option
                      v-for="item in applys_on"
                      :key="item.name"
                      :value="item.name"
                    >
                      {{ $t(`${item.name}`) }}
                    </option>
                  </select>
                  <label for="name_input" class="form-label">
                    منتج / قسم
                  </label>
                </div>
              </div>
              <div class="col-lg-4 py-0">
                <div class="input_wrapper top_label">
                  <input
                    type="number"
                    class="form-control"
                    v-model.trim="data.get_products.get_quantity"
                  />
                  <label for="name_input" class="form-label">
                    كمية الشراء
                  </label>
                </div>
              </div>
              <div class="productRow p-4">
                <div
                  v-for="(row, index) in data.get_products.get_products_rows"
                  :key="row.id"
                >
                  <div class="row productRow">
                    <div
                      class="col-lg-8 py-0"
                      v-if="
                        data.get_products.get_apply_on == 'special_categories'
                      "
                    >
                      <div class="input_wrapper top_label">
                        <label class="form-label">
                          {{ $t("forms.labels.category") }}
                        </label>

                        <multiselect
                          v-model="row.get_category"
                          :options="categories"
                          label="name"
                          track-by="id"
                          placeholder=" "
                          :searchable="true"
                          :allow-empty="false"
                          :show-labels="false"
                        ></multiselect>
                      </div>
                    </div>
                    <div
                      class="col-lg-8 py-0"
                      v-if="
                        data.get_products.get_apply_on == 'special_products'
                      "
                    >
                      <div class="input_wrapper top_label">
                        <label class="form-label">
                          {{ $t("forms.labels.product") }}
                        </label>
                        <!-- {{ row.product }} -->
                        <multiselect
                          v-model="row.product"
                          :options="products"
                          :custom-label="nameWithCode"
                          track-by="id"
                          placeholder=" "
                          :searchable="true"
                          :allow-empty="false"
                          :show-labels="false"
                          @input="getProductDetails(index, 'get')"
                        ></multiselect>
                      </div>
                    </div>

                    <div class="col-11" v-if="row.get_product_details.length">
                      <main>
                        <v-data-table
                          class="elevation-1 thumb diff_table"
                          :headers="headers"
                          :items="row.get_product_details"
                          disable-pagination
                          item-key="id"
                          :show-select="true"
                          v-model="row.selected"
                          hide-default-footer
                        >
                          <!-- ================== You Can use any slots you want ================== -->

                          <template v-slot:no-data>
                            {{ $t("table.noData") }}
                          </template>

                          <template v-slot:[`item.item`]="{ item }">
                            <div class="detail">
                              <div class="productRow mb-0 w-100">
                                <div class="detail_show w-100">
                                  <span class="rate">
                                    {{ item.rate_avg }}
                                    <span><i class="fas fa-star"></i></span>
                                  </span>

                                  <div class="image">
                                    <span class="key">الصورة</span>

                                    <img
                                      v-if="item.media"
                                      :src="item.media[0].image"
                                      alt=""
                                    />
                                    <img
                                      v-else
                                      :src="item.product.image"
                                      alt=""
                                    />
                                  </div>

                                  <div class="color">
                                    <span class="key">اللون</span>
                                    <v-chip
                                      :color="
                                        item.color ? item.color.hex : 'لا يوجد'
                                      "
                                      class="value"
                                    ></v-chip>
                                  </div>

                                  <div class="size">
                                    <span class="key">الكمية</span>
                                    <v-chip
                                      color="#0E3958"
                                      class="value text-white"
                                    >
                                      {{
                                        item.quantity
                                          ? item.quantity
                                          : "لا يوجد"
                                      }}
                                    </v-chip>
                                  </div>

                                  <div class="size">
                                    <div class="size">
                                      <span class="key">الحجم</span>
                                      <v-chip
                                        color="#0E3958"
                                        class="value text-white"
                                      >
                                        {{
                                          item.size ? item.size.name : "لا يوجد"
                                        }}
                                      </v-chip>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </template>
                        </v-data-table>
                      </main>
                    </div>
                    <div
                      class="col-1 py-0 d-flex flex-column justify-center align-center"
                    >
                      <span
                        v-if="
                          index ==
                          data.get_products.get_products_rows.length - 1
                        "
                        class="append mx-1"
                        @click="appendGetRow"
                      >
                        <i class="fas fa-plus-circle"></i>
                      </span>

                      <span
                        v-if="data.get_products.get_products_rows.length > 1"
                        class="append mx-1"
                        @click="deleteGetRow(index)"
                      >
                        <i class="fas fa-minus-circle"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Discount type  -->

        <div class="col-lg-6 py-0">
          <div class="input_wrapper top_label">
            <select
              class="form-control border-0 outline-none"
              style="box-shadow: none;"
              v-model.trim="data.discount_type"
            >
              <option
                v-for="item in discount_types"
                :key="item.id"
                :value="item.name"
              >
                {{ $t(`${item.name}`) }}
              </option>
            </select>

            <label class="form-label">نوع الخصم</label>
          </div>
        </div>
        <div class="col-lg-6 py-0" v-if="data.discount_type == 'percentage'">
          <div class="input_wrapper top_label">
            <input
              type="number"
              class="form-control"
              v-model.trim="data.discount_amount"
            />
            <label for="name_input" class="form-label">
              قيمة الخصم
            </label>
          </div>
        </div>
      </div>

      <!-- Discount type  -->
    </div>

    <div class="buttons_wrapper">
      <button class="button_style_1" :disabled="btnIsLoading">
        <!-- :class="btnIsLoading ? 'disabled' : ''" -->

        {{ $t("forms.submit") }}
        <span class="btn_loader" v-if="btnIsLoading"></span>
      </button>
    </div>
  </form>
  <!-- ==== End Form ==== -->
</template>

<script>
export default {
  name: "xyoffer",
  props: ["static"],
  data() {
    return {
      dateMenu: false,
      dateMenu2: false,
      id: this.$route.params.id || "",
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.offers.title"),
          disabled: false,
          href: "/flash-sales",
        },
        {
          text: this.$t("breadcrumb.offers.add"),
          disabled: true,
          href: "",
        },
      ],

      // ========== Loading
      btnIsLoading: false,

      // ========== create_data
      data: {
        discount_types: "",
        discount: null,

        discount_type: {
          name: "",
        },

        applys_on: "",
        applys_ids: [],
        max_discount: "",
        discount_amount: null,
        buy_products: {
          buy_apply_on: "special_products",
          buy_products_rows: [
            {
              product: null,
              buy_product_details: [],
              buy_category: null,
              selected: [],
            },
          ],
          products_id: [],
          buy_quantity: null,
        },
        get_products: {
          get_apply_on: "special_products",
          get_products_rows: [
            {
              product: null,
              get_category: null,
              get_product_details: [],
              selected: [],
            },
          ],
          products_id: [],
          get_quantity: null,
        },
      },

      // ========== Fixed Data
      products: [],
      categories: [],
    };
  },

  computed: {
    discount_types() {
      return [
        {
          id: "free_product",
          name: "free_product",
        },
        {
          id: "percentage",
          name: "percentage",
        },
      ];
    },
    applys_on() {
      return [
        {
          id: "special_products",
          name: "special_products",
        },
        {
          id: "special_categories",
          name: "special_categories",
        },
      ];
    },
    headers() {
      return [
        {
          text: "المنتج",
          align: "center",
          value: "item",
          sortable: true,
        },
      ];
    },
  },

  methods: {
    uplodeImg_1(obj) {
      this.data.image = obj;
    },

    resetApplys(e) {
      this.data.applys_ids = [];
    },

    appendBuyRow() {
      this.data.buy_products.buy_products_rows.push({
        product: null,
        buy_product_details: [],
        selected: [],
        buy_category: null,
      });
    },
    deleteBuyRow(index) {
      this.data.buy_products.buy_products_rows.splice(index, 1);
    },
    appendGetRow() {
      this.data.get_products.get_products_rows.push({
        product: null,
        get_category: null,
        get_product_details: [],
        selected: [],
      });
    },
    deleteGetRow(index) {
      this.data.get_products.get_products_rows.splice(index, 1);
    },

    getProductDetails(index, type) {
      let id = null;
      if (type == "get") {
        id = this.data.get_products.get_products_rows[index].product.id;
      } else {
        id = this.data.buy_products.buy_products_rows[index].product.id;
      }
      this.$axios({
        method: "GET",
        url: `products/${id}`,
      }).then((res) => {
        if (type == "get") {
          this.data.get_products.get_products_rows[
            index
          ].get_product_details = [];
          this.data.get_products.get_products_rows[index].selected = [];
          this.data.get_products.get_products_rows[index].get_product_details =
            res.data.data.product_details;
        } else {
          this.data.buy_products.buy_products_rows[
            index
          ].buy_product_details = [];
          this.data.buy_products.buy_products_rows[index].selected = [];
          this.data.buy_products.buy_products_rows[index].buy_product_details =
            res.data.data.product_details;
        }
      });
    },
    // getBuyProductDetails(id) {
    //   this.$axios({
    //     method: 'GET',
    //     url: `products/${id}`,
    //   }).then((res) => {
    //     this.data.buy_products_rows[0].product = res.data.data
    //     this.data.buy_products_rows[0].buy_product_details =
    //       res.data.data.product_details
    //   })
    // },
    // getGetProductDetails(id) {
    //   this.$axios({
    //     method: 'GET',
    //     url: `products/${id}`,
    //   }).then((res) => {
    //     this.data.get_products_rows[0].product = res.data.data
    //     this.data.get_products_rows[0].get_product_details =
    //       res.data.data.product_details
    //   })
    // },

    // ====== Validate Data
    validateCreateForm() {
      this.btnIsLoading = true;

      if (this.data.discount_type.name == "percentage ") {
        console.log("dashd");
        if (this.data.discount_amount == 0) {
          this.$iziToast.error({
            timeout: 2000,
            message: "قيمة الخصم مطلوبة",
            position: "bottomRight",
          });
          this.btnIsLoading = false;
          return;
        }
        // } else if (
        //   this.static.display_platform == 'both' &&
        //   !this.static.ar.app_img
        // ) {
        //   this.$iziToast.error({
        //     timeout: 2000,
        //     message: '  صورة العرض في التطبيق بالعربي مطلوب',
        //     position: 'bottomRight',
        //   })

        //   this.btnIsLoading = false
        //   return
        // } else if (
        //   this.static.display_platform == 'both' &&
        //   !this.static.en.app_img
        // ) {
        //   this.$iziToast.error({
        //     timeout: 2000,
        //     message: '  صورة العرض في التطبيق بالانجليزي مطلوب',
        //     position: 'bottomRight',
        //   })

        //   this.btnIsLoading = false
        //   return
        // } else if (
        //   this.static.display_platform == 'both' &&
        //   !this.static.ar.website_img
        // ) {
        //   this.$iziToast.error({
        //     timeout: 2000,
        //     message: '  صورة العرض في الموقع بالعربي مطلوب',
        //     position: 'bottomRight',
        //   })

        //   this.btnIsLoading = false
        //   return
        // } else if (
        //   this.static.display_platform == 'both' &&
        //   !this.static.ar.website_img
        // ) {
        //   this.$iziToast.error({
        //     timeout: 2000,
        //     message: '  صورة العرض في الموقع بالانجليزي مطلوب',
        //     position: 'bottomRight',
        //   })

        //   this.btnIsLoading = false
        //   return
        // } else if (
        //   this.static.display_platform == 'app' &&
        //   !this.static.ar.app_img
        // ) {
        //   this.$iziToast.error({
        //     timeout: 2000,
        //     message: '  صورة العرض في التطبيق بالعربي مطلوب',
        //     position: 'bottomRight',
        //   })

        //   this.btnIsLoading = false
        //   return
        // } else if (
        //   this.static.display_platform == 'app' &&
        //   !this.static.en.app_img
        // ) {
        //   this.$iziToast.error({
        //     timeout: 2000,
        //     message: '  صورة العرض في التطبيق بالانجليزي مطلوب',
        //     position: 'bottomRight',
        //   })

        //   this.btnIsLoading = false
        //   return
        // } else if (
        //   this.static.display_platform == 'website' &&
        //   !this.static.ar.website_img
        // ) {
        //   this.$iziToast.error({
        //     timeout: 2000,
        //     message: '  صورة العرض في الموقع بالعربي مطلوب',
        //     position: 'bottomRight',
        //   })

        //   this.btnIsLoading = false
        //   return
        // } else if (
        //   this.static.display_platform == 'website' &&
        //   !this.static.ar.website_img
        // ) {
        //   this.$iziToast.error({
        //     timeout: 2000,
        //     message: '  صورة العرض في الموقع بالانجليزي مطلوب',
        //     position: 'bottomRight',
        //   })

        //   this.btnIsLoading = false
        //   return
      } else if (!this.static.ar.title || !this.static.ar.title) {
        this.$iziToast.error({
          timeout: 2000,
          message: "  العنوان مطلوب",
          position: "bottomRight",
        });
        console.log("dashd");
        this.btnIsLoading = false;
        console.log("dashd");
        return;
      } else if (!this.static.start_date) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.start_date"),
          position: "bottomRight",
        });
        console.log("dashd");
        this.btnIsLoading = false;
        return;
      } else if (!this.static.end_date) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.end_date"),
          position: "bottomRight",
        });
        console.log("dashd");
        this.btnIsLoading = false;
        return;
      } else if (this.data.discount_type.name == "") {
        this.$iziToast.error({
          timeout: 2000,
          message: "نوع الخصم مطلوب",
          position: "bottomRight",
        });
        console.log("dashd");
        this.btnIsLoading = false;
        return;
      } else if (!this.static.max_use) {
        this.$iziToast.error({
          timeout: 2000,
          message: "اقصي عدد مرات الالاستخدام مطلوب",
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        console.log("dashd");
        console.log("dashd");
        return;
      } else if (!this.static.num_of_use) {
        this.$iziToast.error({
          timeout: 2000,
          message: "عدد مرات الاستخدام",
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        console.log("dashd");
        console.log("dashd");
        return;
      } else {
        this.submitData();
        return;
      }
    },

    // ====== Submit Data
    submitData() {
      const submit_data = new FormData();
      if (this.$route.params.id) {
        submit_data.append("_method", "PUT");
      }
      if (this.static.display_platform == "both") {
        if (this.static.ar.app_img?.img_file) {
          submit_data.append("app_image_ar", this.static.ar.app_img.img_file);
        }
        if (this.static.en.app_img?.img_file) {
          submit_data.append("app_image_en", this.static.en.app_img.img_file);
        }
        if (this.static.ar.website_img?.img_file) {
          submit_data.append(
            "web_image_ar",
            this.static.ar.website_img.img_file
          );
        }
        if (this.static.en.website_img?.img_file) {
          submit_data.append(
            "web_image_en",
            this.static.en.website_img.img_file
          );
        }
      } else if (this.static.display_platform == "website") {
        if (this.static.ar.website_img?.img_file) {
          submit_data.append(
            "web_image_ar",
            this.static.ar.website_img.img_file
          );
        }
        if (this.static.en.website_img?.img_file) {
          submit_data.append(
            "web_image_en",
            this.static.en.website_img.img_file
          );
        }
      } else {
        if (this.static.ar.app_img?.img_file) {
          submit_data.append("app_image_ar", this.static.ar.app_img.img_file);
        }
        if (this.static.en.app_img?.img_file) {
          submit_data.append("app_image_en", this.static.en.app_img.img_file);
        }
      }

      submit_data.append("ar[name]", this.static.ar.title);
      if (this.static.ar.desc) {
        submit_data.append("ar[desc]", this.static.ar.desc);
      }

      submit_data.append("en[name]", this.static.en.title);
      if (this.static.en.desc) {
        submit_data.append("en[desc]", this.static.en.desc);
      }

      submit_data.append("type", "buy_x_get_y");
      submit_data.append("display_platform", this.static.display_platform);
      submit_data.append("discount_type", this.data.discount_type);
      if (this.data.discount_type == "percentage") {
        submit_data.append("discount_amount", this.data.discount_amount);
      }

      submit_data.append(
        "start_at",
        `${this.static.start_date} ${this.static.start_time}`
      );
      submit_data.append(
        "end_at",
        `${this.static.end_date} ${this.static.end_time}`
      );

      if (this.static.ordering) {
        submit_data.append("ordering", this.static.ordering);
      }

      submit_data.append("max_use", this.static.max_use);
      submit_data.append("num_of_use", this.static.num_of_use);

      submit_data.append("is_with_coupon", +this.static.is_with_coupon);

      submit_data.append("is_active", +this.static.is_active);

      submit_data.append(`buy_apply_on`, this.data.buy_products.buy_apply_on);
      submit_data.append(`buy_quantity`, this.data.buy_products.buy_quantity);
      submit_data.append(`get_apply_on`, this.data.get_products.get_apply_on);
      submit_data.append(`get_quantity`, this.data.get_products.get_quantity);

      if (this.data.buy_products.buy_apply_on == "special_products") {
        if (this.data.buy_products.buy_products_rows[0].selected.length) {
          let ids = [];
          this.data.buy_products.buy_products_rows.map((el) => {
            el.selected.map((el) => {
              ids.push(el.id);
            });
          });
          [...new Set(ids)].map((el, index) =>
            submit_data.append(`buy_apply_ids[${index}]`, el)
          );
        } else {
          this.$iziToast.error({
            timeout: 2000,
            message: "ادخل منتجات الشراء",
            position: "bottomRight",
          });
        }
      } else {
        let ids = [];
        this.data.buy_products.buy_products_rows.map((main) => {
          if (main.buy_category) {
            ids.push(main.buy_category.id);
          }
        });
        if (ids.length) {
          ids.map((id, index) =>
            submit_data.append(`buy_apply_ids[${index}]`, id)
          );
        }
      }

      if (this.data.get_products.get_apply_on == "special_products") {
        if (this.data.get_products.get_products_rows[0].selected.length) {
          let ids = [];
          this.data.get_products.get_products_rows.map((el) => {
            el.selected.map((el) => {
              ids.push(el.id);
            });
          });
          ids.map((el, index) =>
            submit_data.append(`get_apply_ids[${index}]`, el)
          );
        } else {
          this.$iziToast.error({
            timeout: 2000,
            message: "ادخل منتجات العرض",
            position: "bottomRight",
          });
        }
      } else {
        let ids = [];
        this.data.get_products.get_products_rows.map((main) => {
          if (main.get_category) {
            ids.push(main.get_category.id);
          }
        });

        if (ids.length) {
          [...new Set(ids)].map((id, index) =>
            submit_data.append(`get_apply_ids[${index}]`, id)
          );
        }
      }

      let url = "";
      if (this.$route.params.id) {
        url = `offer/${this.$route.params.id}`;
      } else {
        url = "offer";
      }

      this.$axios({
        method: "POST",
        url: url,
        data: submit_data,
      })
        .then((data) => {
          this.$iziToast.success({
            timeout: 2000,
            message: data.data.message,
            position: "bottomRight",
          });
          this.$router.push({ path: "/offers" });
          this.btnIsLoading = false;
          this.$store.commit("offerTypeEdit", "");
        })
        .catch((err) => {
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "bottomRight",
          });
          this.btnIsLoading = false;
        });
    },

    // ====== Products
    getProducts() {
      this.$axios({
        method: "GET",
        url: `product/without_paginate`,
      }).then((res) => {
        this.products = res.data.data.map((item) => {
          return {
            id: item.id,
            name: item.name,
            code: item.code,
          };
        });
      });
    },
    // ====== categories
    getCategories() {
      this.$axios({
        method: "GET",
        url: `last-categories`,
      }).then((res) => {
        this.categories = res.data.data.map((item) => {
          return {
            id: item.id,
            name: item.name,
          };
        });
      });
    },
    getBuyCategory(id) {
      this.$axios({
        method: "GET",
        url: `categories/${id}`,
      }).then((res) => {
        this.data.buy_products_rows[0].buy_category = res.data.data;
        this.data.buy_products_rows[0].buy_category.name =
          res.data.data.ar.name;
      });
    },
    getGetCategory(id) {
      this.$axios({
        method: "GET",
        url: `categories/${id}`,
      }).then((res) => {
        this.data.get_products_rows[0].get_category = res.data.data;
        this.data.get_products_rows[0].get_category.name =
          res.data.data.ar.name;
      });
    },
    clearDataBuyProductsRow() {
      this.data.buy_products.buy_products_rows = [];
      this.appendBuyRow();
    },
    clearDataProductsRow() {
      this.data.get_products.get_products_rows = [];
      this.appendGetRow();
    },
    getData() {
      this.$axios({
        method: "GET",
        url: `offer/${this.id}`,
      }).then((res) => {
        let result = res.data.data;

        if (result.by_to_get) {
          this.$store.commit("offerTypeEdit", "buy_to_get");

          this.data.discount_type = result.by_to_get.discount_type;
          this.data.discount_amount = result.by_to_get.discount_amount;
          this.data.buy_products.buy_apply_on = result.by_to_get.buy_apply_on;
          this.data.get_products.get_apply_on = result.by_to_get.get_apply_on;
          this.data.buy_products.buy_quantity = result.by_to_get.buy_quantity;
          this.data.get_products.get_quantity = result.by_to_get.get_quantity;

          if (result.by_to_get.buy_apply_on == "special_products") {
            let currentData = [];
            let newData = [];
            result.by_to_get.buy_apply.map((el) => {
              let obj = {};

              obj["product"] = el.product;
              obj["product_details"] = el;
              currentData.push(obj);
            });
            currentData.map((parent) => {
              let obj = {};
              const found = newData.find(
                (f) => parent.product.id == f.product.id
              );
              if (found) {
                found.products_details.push(parent.product_details);
              } else {
                obj["product"] = parent.product;
                obj["products_details"] = [parent.product_details];
                newData.push(obj);
              }
            });
            this.data.buy_products.buy_products_rows = [];
            newData.map((element) => {
              this.$axios({
                method: "GET",
                url: `products/${element.product.id}`,
              }).then((res) => {
                this.data.buy_products.buy_products_rows.push({
                  product: element.product,
                  buy_product_details: res.data.data.product_details,
                  selected: [...element.products_details],
                  buy_category: null,
                });
              });
            });
          } else {
            result.by_to_get.buy_apply.map((el, index) => {
              this.data.buy_products.buy_products_rows[index] = {};

              this.data.buy_products.buy_products_rows[
                index
              ].buy_product_details = [];

              this.data.buy_products.buy_products_rows[index].buy_category = el;
            });
          }

          if (result.by_to_get.get_apply_on == "special_products") {
            let currentData = [];
            let newData = [];
            result.by_to_get.get_apply.map((el) => {
              let obj = {};

              obj["product"] = el.product;
              obj["product_details"] = el;
              currentData.push(obj);
            });
            currentData.map((parent) => {
              let obj = {};
              const found = newData.find(
                (f) => parent.product.id == f.product.id
              );
              if (found) {
                found.products_details.push(parent.product_details);
              } else {
                obj["product"] = parent.product;
                obj["products_details"] = [parent.product_details];
                newData.push(obj);
              }
            });
            this.data.get_products.get_products_rows = [];
            newData.map((element) => {
              this.$axios({
                method: "GET",
                url: `products/${element.product.id}`,
              }).then((res) => {
                this.data.get_products.get_products_rows.push({
                  product: element.product,
                  get_product_details: res.data.data.product_details,
                  selected: [...element.products_details],
                  get_category: null,
                });
              });
            });
          } else {
            result.by_to_get.get_apply.map((el, index) => {
              this.data.get_products.get_products_rows[index] = {};

              this.data.get_products.get_products_rows[
                index
              ].get_product_details = [];

              this.data.get_products.get_products_rows[index].get_category = el;
            });
          }
        }
      });
    },
    nameWithCode({ name, code }) {
      return `${name} — كود ${code}`;
    },
  },

  created() {
    if (this.$route.params.id) {
      this.getData();
    }
    this.getProducts();
    this.getCategories();
  },
};
</script>

<style>
.form-label.title {
  color: var(--mainColor-1) !important;
}
</style>
